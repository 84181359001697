import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BannerDetail from 'components/elements/Misc/BannerDetail'
import Share from 'components/elements/Misc/Share'

const SideWrapper = styled.div`
  position: relative;
  @media (min-width: 992px) {
    margin-top: -9rem;
  }
`

const Content = styled(ParseContent)`
  & img {
    object-fit: contain !important;
  }
`

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      postdetail: any
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title, postdetail, flexContent, seo },
  },
  location = {},
  prefix = 'post_Flexcontent',
  pageContext,
}) => (
  <Layout>
    <SEO seo={seo} />
    <BannerDetail
      image={postdetail.bannerimage}
      title={title || 'Nieuwsbericht'}
      type="default"
      pageContext={pageContext}
    />
    <section className="mb-5 pb-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {/* <ParseContent
              content={postdetail.shortdescription}
              className="mb-5"
            /> */}
            <Content content={postdetail.description} className="mb-5" />
          </div>
          <SideWrapper className="col-lg-4">
            <Share
              heading="Deel dit artikel"
              location={location}
              subject={title || 'Vacature'}
              body={postdetail.shortdescription}
            />
          </SideWrapper>
        </div>
      </div>
    </section>
    <FlexContentHandler
      prefix={prefix}
      fields={flexContent}
      location={location}
      pageContext={pageContext}
    />
  </Layout>
)

export const pageQuery = graphql`
  query postById($pageId: String!) {
    page: wpPost(id: { eq: $pageId }) {
      ...generalPostFragment
    }
  }
`

export default PageTemplate
